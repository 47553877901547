.writing {
  padding: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.writing h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #333;
}

.writing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.writing-item {
  padding: 1.5rem;
  background-color: #f4f4f4;
  border-radius: 4px;
  text-decoration: none;
  color: #333;
  transition: transform 0.3s ease;
}

.writing-item:hover {
  transform: translateY(-5px);
}

.writing-item h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.writing-item p {
  font-size: 1.2rem;
  color: #666;
}
