.back-button {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #333;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 0.8rem;
}

.back-button:hover {
  background-color: #555;
}

.back-button i {
  margin-right: 0.5rem;
}
