#resume {
  padding: 50px 0;
}

.resume-heading {
  font-size: 2rem;
  margin-bottom: 20px;
}

.resume-content {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Set a custom width for the resume content */
  width: 400%; /* Adjust this value to your desired width */
  margin-left: -200%; /* Offset the left by 50% of the parent's width */
  position: relative; /* Needed for left positioning */
  left: 50%; /* Center the content horizontally */
}

@media (max-width: 768px) {
  /* Adjust styles for smaller screens if needed */
  .resume-content {
    width: 100%;
    margin-left: 0; /* Reset margin-left for smaller screens */
    left: 0; /* Reset left for smaller screens */
    transform: none; /* Reset transform for smaller screens */
  }
}
