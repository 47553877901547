/* global.css */

/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  background-color: #f4f4f4;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  background-color: #333;
  padding: 1.5rem;
  color: white;
}

nav ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin-right: 1.5rem;
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s;
}

nav ul li a:hover {
  color: #ffcc00;
}

main {
  flex: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

h1,
h2,
h3 {
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1.5rem;
}

footer {
  background-color: #333;
  padding: 1rem;
  color: white;
  text-align: center;
}

/* Custom styles for Header */

.header {
  background-color: #333;
  padding: 1.5rem;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo h1 {
  font-size: 2rem;
  font-weight: 700;
  color: white;
  margin: 0;
  display: flex;
  align-items: center;
}

.logo-link {
  text-decoration: none; /* Ensures no underline */
  color: inherit; /* Ensures the text inherits the color from the parent */
}

.nav-links {
  list-style-type: none;
  display: flex;
}

.nav-links li {
  margin-left: 2rem;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  transition: color 0.3s;
}

.nav-links li a:hover {
  color: #ffcc00;
}

footer {
  background-color: #333;
  padding: 2rem;
  color: white;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-social {
  list-style-type: none;
  display: flex;
}

.footer-social li {
  margin-left: 1.5rem;
}

.footer-social li a {
  color: white;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.footer-social li a:hover {
  color: #ffcc00;
}
