.journal-content {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

@media (min-width: 768px) {
  .journal-content {
    max-width: 800px; /* Adjust this value to your desired maximum width */
    margin: 0 auto; /* Center the content horizontally */
  }
}
